//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import headMixin from '@/mixins/head.mixin'

export default {
  name: 'MainPartner',
  components: {
    StartSection: () => import('@/components/partner/StartSection'),
    StatsSection: () => import('@/components/partner/StatsSection'),
    SignupSection: () => import('@/components/partner/SignupSection'),
    NavigationSection: () => import('@/components/partner/NavigationSection'),
    HintSection: () => import('@/components/partner/HintSection'),
    SkillsSection: () => import('@/components/partner/SkillsSection'),
    WorkflowSection: () => import('@/components/partner/WorkflowSection'),
    TeacherSection: () => import('@/components/partner/TeacherSection'),
    VideoSection: () => import('@/components/partner/VideoSection'),
    MapSection: () => import('@/components/partner/MapSection'),
    QuestionsSection: () => import('@/components/partner/QuestionsSection'),
    FeedbackSection: () => import('@/components/partner/FeedbackSection'),
    NewsSection: () => import('@/components/partner/NewsSection'),
    ShearsDivider: () => import('@/components/common/ShearsDivider'),
    GallerySection: () => import('@/components/partner/GallerySection'),
    CommunitySection: () => import('@/components/partner/CommunitySection'),
    PartnerFooter: () => import('@/components/common/footer/PartnerFooter'),
  },
  mixins: [headMixin],
}
